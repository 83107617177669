// const React = require("react")
// const Layout = require("./src/components/layout")
const ReactDOM = require("react-dom/client")

// Logs when the client route changes
exports.replaceHydrateFunction = () => {
    return (element, container) => {
      const root = ReactDOM.createRoot(container)
      root.render(element)
    }
  }